

























import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {Getter} from 'vuex-class'
import {FieldConfigs, ReservationResponse} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import moment from 'moment'
import DateHelper from '@/lib/DateHelper'
import ReservationHelper from '@/lib/reservation'

@Component({
  components: {
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    ColorCard: Utils.loadComponent('ColorCard'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CardTitle: Utils.loadComponent('proxy/Card/CardTitle'),
    VehicleQuickSummary: Utils.loadComponent('entities/vehicle/VehicleQuickSummary'),
    FuelLevel: Utils.loadComponent('FuelLevel'),
  },
  name: 'ReservationVehicleAndDates',
})
export default class ReservationVehicleAndDates extends Vue {
  @Getter('fieldConfigs') public fieldConfigs!: FieldConfigs
  @Prop() public reservation!: ReservationResponse
  public isFree: boolean = false
  public isLate: boolean = false
  public howMuch: number = 0
  public progress: number | 'indeterminate' = 0
  public counter: NodeJS.Timeout | null = null

  public get hideStatus() {
    const conf = this.fieldConfigs.hide_reservation_status?.toLowerCase().split('|')
    const bm = this.reservation.type.toLowerCase()
    const vt = this.reservation.vehicle_slot.vehicle.category.type.toLowerCase()
    if (conf) {
      return conf.includes(bm + vt)
    }
  }

  public get formattedCounter() {
    return DateHelper.formatTimeOffset('dhm', Math.abs(this.howMuch))
  }

  public get statusColor() {
    if (this.isFree) {
      return 'success'
    } else if (this.isLate) {
      return 'error'
    } else {
      return 'primary'
    }
  }

  @Watch('reservation', {immediate: true})
  public onReservationChange() {
    clearInterval(this.counter || undefined)
    const now = moment()
    const freeUntil = moment(this.reservation.free_until, 'YYYY-MM-DD HH:mm:ss')
    const start = moment(this.reservation.start, 'YYYY-MM-DD HH:mm:ss')
    const end = moment(this.reservation.end, 'YYYY-MM-DD HH:mm:ss')
    const free = now.isSameOrBefore(freeUntil)
    if (free) {
      this.isFree = true
      this.howMuch = moment(this.reservation.free_until, 'YYYY-MM-DD HH:mm:ss').diff(now, 'seconds')
      const freeSpent = now.diff(start, 'minutes')
      const totalFree = freeUntil.diff(start, 'minutes')
      this.progress = Math.round(100 / totalFree * freeSpent)
      this.counter = setInterval(() => {
        this.howMuch--
      }, 1000)
    } else if (ReservationHelper.isLate(this.reservation)) {
      this.isLate = true
      this.howMuch = now.diff(moment(this.reservation.end, 'YYYY-MM-DD HH:mm:ss'), 'seconds')
      this.counter = setInterval(() => {
        this.howMuch++
      }, 1000)
    } else {
      this.isFree = false
      this.isLate = false
      this.howMuch = 0
      if (this.reservation.type.endsWith('RT')) {
        this.progress = Math.round(100 / start.diff(end, 'minutes') * start.diff(now, 'minutes'))
      } else {
        this.progress = 0
      }
    }
  }
}
